import { render, staticRenderFns } from "./UserSettingsPass.vue?vue&type=template&id=5d07105b&"
import script from "./UserSettingsPass.vue?vue&type=script&lang=js&"
export * from "./UserSettingsPass.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports