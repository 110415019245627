<template>
  <div>
    <titleBar
      :title="'Edit Profile'"
      :img="user?.avatar"
      back="auto"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
    />

    <div class="sectionNA">
      <div class="containerNA NHAw800">
        <b-field horizontal label=" ">
          <header>
            <h2 class="title is-2">
              <span>Change password </span>
            </h2>
          </header>
        </b-field>

        <hr />

        <!-- START-->

        <b-field horizontal label="New password (8+ chars)">
          <b-input
            size="is-"
            placeholder="your unique secret password"
            v-model="pass"
            :loading="posting"
            :disabled="posting"
            type="password"
          ></b-input>
        </b-field>

        <!-- END     
    -->
        <b-field horizontal label="   ">
          <b-button type="is-info is-outlined" @click="saveNewPass" :loading="posting" :disabled="posting"
            >Save new password</b-button
          >
        </b-field>

        <hr />
      </div>
    </div>
  </div>
</template>

<script>
import Editor from "@/components/e/EditorV";

export default {
  //name: "readmeEditor",
  components: {
    //Editor,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    posting: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pass: "",
      // readmeJson: defaultReadme, // "432432432",
    };
  },
  methods: {
    saveNewPass() {
      var d = this.user;
      var pass = this.pass;
      window.API.updatePassword(d.handle, { pass: pass })
        .then((bot) => {
          this.posting = false;
          //snackbar black buefy
          this.$buefy.snackbar.open({
            message: "Password updated!",
            type: "is-success",
            position: "is-bottom-right",
            actionText: "Close",
            indefinite: false,
          });
          this.pass = "";

          //bot updated!
        })
        .catch((err) => {
          this.posting = false;
          //snackbar black buefy
          this.$buefy.snackbar.open({
            message: "Error updating password, make sure it's at least 8 characters long.",
            type: "is-danger",
            position: "is-bottom-right",
            actionText: "Close",
            indefinite: false,
          });
        });
    },
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style></style>
